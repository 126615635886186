<template>
  <div
      class="rounded border-2 bg-tb-white border-tb-gray mb-3">
    <div class="grid grid-cols-12 py-2 pl-4">
      <div class="col-span-5">
        <h3 class="cursor-pointer hover:underline truncate" :title="tender.aanbestedingNaam"
            @click="toggleShow(tender.publicatieId)">{{ tender.aanbestedingNaam }}</h3>
        <p class="select-all text-tb-darkgray truncate" :title="tender.aanbestedendeDienstNaam">
          {{ tender.aanbestedendeDienstNaam }}</p>
      </div>
      <div class="flex flex-col col-span-3 text-right">
        <span v-if="tender.inscriptions" class="text-tb-darkgray">{{ tender.inscriptions }} inschrijvers</span>
        <h4 v-if="tender.contractor" class="cursor-pointer hover:underline truncate" :title="tender.contractor"
            @click="toggleShow(tender.publicatieId)">{{ tender.contractor }}</h4>
      </div>
      <div class="flex justify-center col-span-1 items-center cursor-default" title="Europees">
        <h3 v-if="tender.europees"
            class="flex justify-center items-center w-8 h-8 rounded bg-tb-gray text-tb-secondary">€</h3>
      </div>
      <div class="flex col-span-2 justify-end items-center text-right text-sm text-tb-darkgray">{{
          tender.date
        }}
      </div>
      <div class="flex justify-center items-center">
        <button @click="toggleShow(tender.publicatieId)"
                class="flex justify-center items-center w-8 h-8 rounded bg-tb-gray text-tb-darkgray">
          <svg xmlns="http://www.w3.org/2000/svg" class="w-5 h-5 transform transition ease-in-out duration-150" :class="show.includes(tender.publicatieId) ? 'rotate-180' : ''" fill="none" viewBox="0 0 24 24"
               stroke="currentColor">
            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                  d="M19 13l-7 7-7-7m14-8l-7 7-7-7"/>
          </svg>
        </button>
      </div>
    </div>
    <collapse-transition :duration="180">
      <div v-show="show.includes(tender.publicatieId)" class="bg-tb-light py-2 pl-4">
        <div class="grid grid-cols-12">
          <div class="col-span-5 mb-3">
            <span class="select-all">{{ tender.cpv }}</span>
          </div>
          <div class="flex flex-col col-span-3 text-right">
            <div class="truncate" v-html="tender.website"></div>
            <span v-if="tender.contractor" :class="tender.mkb && tender.mkb !== 'neen' ? '' : 'text-tb-slate'">MKB: {{
                tender.mkb ? tender.mkb : 'neen'
              }}</span>
          </div>
          <div></div>
          <div class="flex flex-col col-span-3 text-right pr-6">
            <span>{{ tender.type }}</span>
            <span :class="tender.procedure ? '' : 'text-tb-slate'">{{
                tender.procedure ? tender.procedure : 'Geen procedure vermeld'
              }}</span>
            <span :class="tender.percelen && tender.percelen !== 'neen' ? '' : 'text-tb-slate'">Percelen: {{
                tender.percelen ? tender.percelen : 'neen'
              }}</span>
          </div>

          <div class="col-span-8 mb-1">
            <div class="text-tb-darkgray" v-html="`<p>${tender.description}</p>`"></div>
          </div>
        </div>
      </div>
    </collapse-transition>
  </div>
</template>

<script>
import {CollapseTransition} from "@ivanv/vue-collapse-transition"

export default {
  props: {
    tender: Object
  },
  components: {
    CollapseTransition,
  },
  data() {
    return {
      show: [],
    }
  },
  methods: {
    toggleShow(i) {
      this.show.includes(i) ? this.show.splice(this.show.indexOf(i), 1) : this.show.push(i)
    }
  }
}
</script>