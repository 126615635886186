import { render, staticRenderFns } from "./TextMorph.vue?vue&type=template&id=2c5e83da&scoped=true&"
import script from "./TextMorph.vue?vue&type=script&lang=js&"
export * from "./TextMorph.vue?vue&type=script&lang=js&"
import style0 from "./TextMorph.vue?vue&type=style&index=0&id=2c5e83da&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2c5e83da",
  null
  
)

export default component.exports