<template>
  <div>
    <div class="flex justify-center bg-tb-light">
      <div class="pt-12 pb-6 w-gold">
        <div class="mb-1">
          Waar bent u naar op zoek?
        </div>
        <div class="grid grid-cols-12 gap-3 justify-between">
          <input v-model="search" class="col-span-6"
                 placeholder="Zoek naar aanbestedingen, leveranciers">
          <input v-model="pastDate" :max="futureDate" type="date" :class="pastDate ? '' : 'text-tb-slate'"
                 class="col-span-2">
          <input v-model="futureDate" :min="pastDate" type="date" :class="futureDate ? '' : 'text-tb-slate'"
                 class="col-span-2">

          <div class="flex justify-end items-center">
            <button @click="showFilters = !showFilters"
                    class="hidden flex justify-center items-center w-1/2 h-full rounded bg-tb-gray text-tb-darkgray">
              <svg xmlns="http://www.w3.org/2000/svg" class="w-5 h-5 transform transition ease-in-out duration-150"
                   :class="showFilters ? 'rotate-180' : ''" fill="none" viewBox="0 0 24 24"
                   stroke="currentColor">
                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                      d="M19 13l-7 7-7-7m14-8l-7 7-7-7"/>
              </svg>
            </button>
          </div>
          <button @click="getTenders(search)" class="font-bold rounded bg-tb-primary text-tb-white"
                  :class="searching ? 'cursor-wait' : ''" :disabled="searching">Zoeken
          </button>
        </div>

        <collapse-transition :duration="180">
          <div v-if="showFilters" class="grid grid-cols-12 gap-3 justify-between my-3">

<!--            <select v-model="publication" class="col-span-2 block w-full pl-3 pr-10">-->
<!--              <optgroup label="Swedish Cars">-->
<!--                <option value="volvo">Volvo</option>-->
<!--                <option value="saab">Saab</option>-->
<!--              </optgroup>-->
<!--              <optgroup label="German Cars">-->
<!--                <option value="mercedes">Mercedes</option>-->
<!--                <option value="audi">Audi</option>-->
<!--              </optgroup>-->
<!--            </select>-->
<!--            -->
<!--            <input v-model="publication" class="col-span-2"-->
<!--                   placeholder="Type publicatie">-->
<!--            <input v-model="type" class="col-span-2"-->
<!--                   placeholder="Type opdracht">-->
          </div>
        </collapse-transition>

        <div v-if="tenders">
          <div class="hidden md:flex border-b border-tb-gray mt-6 -mb-6 bg-tb-light">
            <button v-for="i in 2" :key="i" type="button" tabindex="0"
                    class="rounded-t flex z-0 flex-col flex-grow-0 px-4 py-2 mr-4 cursor-pointer"
                    :style="tab === i ? 'border-bottom: white 1px solid; margin-bottom: -1px;' : ''"
                    @click="tab = i"
                    :class="tab === i ? 'bg-white text-tb-dark border-t border-r border-l border-tb-gray' : 'text-tb-darkgray bg-tb-gray'"
            >
              <h3>{{ i % 2 ? 'Aankondigingen' : 'Leveranciers' }} <span class="text-tb-secondary font-medium">({{
                  i % 2 ? tenders.totalElements : tenders.totalContractors
                }})</span></h3>
            </button>
          </div>
        </div>
      </div>
    </div>

    <div class="flex justify-center mt-8">

      <div v-if="searching" style="width: 61.8%" class="h-full mt-12 flex items-center justify-center text-tb-accent">
        <svg class="w-12 h-12 animate-spin" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
          <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle>
          <path class="opacity-75" fill="currentColor"
                d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
        </svg>
      </div>

      <div v-else-if="tenders && tab === 1" style="width: 61.8%">
        <TenderResult v-for="tender in tenders.content" :key="tender.publicatieId" :tender="tender"/>
      </div>

      <div v-else-if="tenders && tab === 2" style="width: 61.8%">
        <ContractorResult v-for="contractor in tenders.contractors" :key="contractor.name" :contractor="contractor"/>
      </div>

      <div v-else class="flex justify-center text-tb-slate" style="width: 61.8%">
        Vul een zoekopdracht in en begin met zoeken
      </div>

    </div>

  </div>
</template>

<script>
import axios from "axios";
import {CollapseTransition} from "@ivanv/vue-collapse-transition"

import TenderResult from "@/views/Search/components/TenderResult";
import ContractorResult from "@/views/Search/components/ContractorResult";

export default {
  components: {
    CollapseTransition,
    ContractorResult,
    TenderResult,
  },
  data() {
    return {
      search: '',

      pastDate: new Date(
          new Date().getFullYear(),
          new Date().getMonth() - 1,
          new Date().getDate()
      ).toISOString().substr(0, 10),
      futureDate: new Date().toISOString().substr(0, 10),
      publication: '',
      type: '',

      searching: false,
      showFilters: false,
      tenders: null,
      tab: 1,
    }
  },
  methods: {
    getTenders(search) {
      this.searching = true;

      axios.get('publications', {
        params: {
          q: search,
          pastDate: new Date(this.pastDate).getTime() / 1000,
          futureDate: new Date(this.futureDate).getTime() / 1000,
        }
      }).then(response => {
        this.tenders = response.data;
      }).catch(error => {
        console.error(error);
      }).finally(() => {
        this.searching = false;
      })
    },
  }
}
</script>