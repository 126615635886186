import Vue from 'vue';
import App from './App.vue';
import '@/core/http/axios.config';
import './index.css';

import Notifications from 'vue-notification';

import store from './store/index';
import router from './router/index';

Vue.config.productionTip = false;

Vue.use(Notifications);

new Vue({
  store,
  router,
  render: h => h(App)
}).$mount('#app')
