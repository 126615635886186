import Vue from 'vue';
import VueRouter from 'vue-router';
import {routes} from '@/views/routes.js';

Vue.use(VueRouter);

const router = new VueRouter({
    mode: 'history',
    routes,
    scrollBehavior() {
        return {x: 0, y: 0}
    }  //Always scroll to top of page
});

export default router;