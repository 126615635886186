<template>
  <div class="rounded border-2 bg-tb-white border-tb-gray mb-3">
    <div class="grid grid-cols-12 py-2 pl-4">
      <div class="col-span-5">
        <h3 class="select-all truncate" :title="contractor.name">{{ contractor.name }}</h3>
        <div class="truncate" v-html="contractor.website"></div>
      </div>
      <div class="col-span-3 text-right">
        <p class="select-all">{{ contractor.phone }}</p>
        <div class="truncate" v-html="contractor.email"></div>
      </div>
      <div class="col-span-3 text-right">
        <p>{{ contractor.tenders }} {{ contractor.tenders === 1 ? 'aanbesteding' : 'aanbestedingen'}}</p>
        <span :class="contractor.mkb && contractor.mkb !== 'neen' ? '' : 'text-tb-slate'">MKB: {{
            contractor.mkb ? contractor.mkb : 'neen'
          }}</span>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  props: {
    contractor: Object
  }
}
</script>