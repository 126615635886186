import Home from '@/views/Home/Home.vue'
import Search from '@/views/Search/Search.vue'

export const routes = [
    {
        path: '/',
        name: 'Home',
        component: Home
    },
    {
        path: '/search',
        name: 'Search',
        component: Search
    }
]