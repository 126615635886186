<template>
  <div>
    <div class="flex justify-center" style="height: 618px;">
      <div class="w-gold">
        <div class="grid-cols-12 h-full xl:grid">
          <div class="flex relative z-10 col-span-5 items-center bg-white">
            <div>
              <h1 class="mt-12 text-4xl xl:leading-tight xl:text-5xl xl:mt-0">
                <TextMorph class="mb-10 h-0 font-sans font-black xl:mb-16 text-tb-accent"/>
                <span class="text-tb-primary">aanbesteden</span> was<br>nog nooit zo <span class="text-tb-primary">simpel</span>
              </h1>
              <div class="mt-2 text-sm text-tb-darkgray">Tenderbot genereert al uw aanbestedingen – klein en groot.<br class="hidden xl:block">
                    Gegarandeerd kwaliteit en snelheid door templates, best<br class="hidden xl:block"> practices en slimme algoritmes.
              </div>
              <button class="px-5 py-2 mt-6 text-sm font-bold rounded bg-tb-primary text-tb-white">Beginnen</button>
            </div>
          </div>
          <div class="bg-white xl:-ml-20 xl:-my-12 oval"></div>
        </div>
      </div>

      <div class="hidden absolute right-0 bottom-0 top-16 justify-center items-center mt-2 w-1/2 bg-tb-light xl:flex" style="height: 618px;">
        <div class="grid grid-cols-3 w-full">
          <div></div>
          <div class="col-span-2">
            <div class="bg-tb-white rounded border border-tb-gray p-2 my-2">
              <div class="m-2 w-36 bg-tb-slate h-1"></div>
              <div class="m-2 w-16 bg-tb-gray h-1"></div>
            </div>
            <div class="bg-tb-white rounded border border-tb-gray p-2 my-2">
              <div class="m-2 w-48 bg-tb-slate h-1"></div>
              <div class="m-2 w-16 bg-tb-gray h-1"></div>
            </div>
            <div class="bg-tb-white rounded border border-tb-gray p-2 my-2">
              <div class="m-2 w-24 bg-tb-slate h-1"></div>
              <div class="m-2 w-12 bg-tb-gray h-1"></div>
            </div>
          </div>
        </div>
      </div>

    </div>
    <div class="relative z-10 flex justify-center">
      <div class="grid grid-cols-3 w-gold gap-3 py-8">
        <span class="p-5 px-8"><b>Alle procedures </b><span class="text-tb-slate">europees openbaar, enkelvoudig onderhands, niet-openbaar, etc.</span></span>
        <span class="p-5 px-8"><b>Digitale overzichten </b><span class="text-tb-slate">aanbestedingskalender, intake overzicht, contracten, etc.</span></span>
        <span class="p-5 px-8"><b>Documenten </b><span class="text-tb-slate">leidraad, strategie, programma van eisen, prijzenblad, gunningscriteria, etc.</span></span>
      </div>
    </div>
  </div>
</template>

<script>
import TextMorph from "@/views/Home/components/TextMorph.vue";

export default {
  components: {
    TextMorph
  }
}
</script>

<style scoped>
.oval {
  width: 240px;
  border-radius: 50%;
  z-index: 1;
}
</style>